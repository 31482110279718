import "./learning.scss";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumb from 'components/pages/learning/breadCrumb';
import { useTranslation } from "react-i18next";

const Lesson = () => {
  const { lessonId } = useParams();
  useEffect(() => {
    document.getElementById("content").scrollTo(0, 0);
  }, []);
  const { t, i18n } = useTranslation('learning');
  const mainLocale = i18n.language.split('-')[0];
  
  return (
    <>
    <div className="action-bar">
      <BreadCrumb name={`${t('all_lessons')} › Lesson`} lessonId={`Lesson ID: ${lessonId}`} />
    </div>
    <div className="main">
      <iframe 
        src={`https://universe.tiny.science/embed/${lessonId}`}
        title="Lesson Content"
        className="lesson-frame"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
    </>
  );
};

export default Lesson;
