import './breadCrumb.scss';
import React from 'react';

const BreadCrumb = ({ name, lessonId }) => {
    

    return (
        <div className="breadcrumb-container">
            <div className='breadcrumbs preheader m0 text-purple'>
                {name}
            </div>
            <div className='lesson-id preheader m0 text-purple'>
                {lessonId}
            </div>
        </div>
    );
};

export default BreadCrumb;
