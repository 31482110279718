import "./learning.scss";
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PinInput from 'components/global/pinInput';
import { useNavigate } from 'react-router-dom';

const Learning = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page
    document.getElementById("content").scrollTo(0, 0);
  }, []);
  const { t, i18n } = useTranslation('learning');
  const mainLocale = i18n.language.split('-')[0];
  

  // Add state for PIN
  const [lessonPin, setLessonPin] = useState(['', '', '', '', '', '']);

  // Add effect to watch for complete PIN
  useEffect(() => {
    // Check if all PIN digits are filled
    if (lessonPin.every(digit => digit !== '')) {
      const fullPin = lessonPin.join('');
      navigate(`/lesson/${fullPin}`);
    }
  }, [lessonPin, navigate]);

  return (
    <div className="main">
      <p className="text-purple">Write the Lesson ID your teacher gave you below:</p>
      <PinInput 
        pin={lessonPin} 
        setPin={setLessonPin} 
        length={6}
        t={t} 
      />
    </div>
  );
};

export default Learning;
